import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dialog,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  Grid,
  IconButton,
  Divider,
} from "@mui/material";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { getS3ApiJsonKey, useQueryParams } from "../../../utils";
import "../ContainerView/CourseContainerView.scss";
import CarosalBlockView from "../courseViews/CarosalBlockView/CarosalBlockView";
import { AudioView } from "../courseViews/AudioView/AudioView";
import ButtonView from "../courseViews/ButtonView/ButtonView";
import ComicView from "../courseViews/ComicView/ComicView";
import ContainerView from "../courseViews/ContainerView/ContainerView";
import DiscussionView from "../courseViews/DiscussionView/DiscussionView";
import DividerView from "../courseViews/DividerView/DividerView";
import EmbedView from "../courseViews/EmbedView/EmbedView";
import GifView from "../courseViews/GifView/GifView";
import ImageView from "../courseViews/ImageView/ImageView";
import LinkView from "../courseViews/LinkView/LinkView";
import MCQView from "../courseViews/MCQView/MCQView";
import TextViews from "../courseViews/TextView/TextView";
import ToggleList from "../courseViews/ToggleListView/ToggleList";
import { VideoView } from "../courseViews/VideoView/VideoView";
import { FileView } from "../courseViews/FileView/FileView";
import FillView from "../courseViews/FillView/FillView";
import MatchView from "../courseViews/MatchView/MatchView";
import ResponseView from "../courseViews/ResponseView/ResponseView";
import GridView from "../courseViews/GridView/GridView";
import ListView from "../courseViews/ListView/ListView";
import { CourseDetailsSkeleton } from "../../Loader/Loader";
import NothingHere from "../../NothingHere/NothingHere";
import NativeEmbedView from "../courseViews/NativeEmbedView/NativeEmbedView";
import ScormView from "../courseViews/ScormView/ScormView";
import SurveyMonkeyView from "../courseViews/SurveyMonkeyView/SurveyMonkeyViews";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SectionModal = ({ isModalOpen, handleModalClose, completedModulesData, courseOne }) => {
  const [blockLoading, setBlockLoading] = useState(false);
  const [courseBlocks, setCourseBlocks] = useState([]);
  const [containerDetails, setContainerDetails] = useState([]);
  const [blockUserActivities, setBlockUserActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  let { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};
  const navigate = useNavigate();
  let location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [inputValue, setInputValue] = useState(activeStep + 1);
  const [moduleDetails, setModuleDetails] = useState([]);
  const { cid, mid } = useQueryParams();
  let UserData = useSelector((state) => state.userLogin.data);
  const [selectedModule, setSelectedModule] = React.useState("");

  const handleChange = (event) => {
    setSelectedModule(event.target.value);
  };
  useEffect(() => {
    const fetchContainerDetails = async () => {
      try {
        setLoading(true);
        const url = `${getS3ApiJsonKey}/course/${cid}/mod-${mid}.json`;
        const response = await fetch(url);

        if (response.status === 200) {
          const data = await response.json();
          setContainerDetails(data);
        } else {
          const response = await GET(`${Constants.getPublicCourseContainer}/${mid}/${cid}`);
          setContainerDetails(JSON.parse(response?.data?.data));
        }
        setLoading(false);
      } catch (error) {
        navigate("/404");
      }
    };

    if (mid) {
      fetchContainerDetails();
    }
  }, [isModalOpen, mid]);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          //get block user activity
          const response = await GET(
            `${Constants.getPublicCourseBlock}/blockUserActivity/${cid}/${UserData?.user?.id}`,
          );
          setBlockUserActivities(response?.data);
        } catch (error) {
          // navigate("/404");
          console.log(error);
        }
      })();
    }
  }, [cid, containerDetails, isModalOpen]);

  const getCourseBlocks = async () => {
    setBlockLoading(true);
    try {
      const url = `${getS3ApiJsonKey}/course/${courseOne._id}/landing.json`;
      const response = await fetch(url);

      if (response.ok) {
        const data = await response.json();
        setCourseBlocks(data);
        setModuleDetails(data?.filter((block) => block.type === "container"));
      } else {
        const publicResponse = await GET(`${Constants.getPublicCourseBlock}/${courseOne._id}`);
        setCourseBlocks(JSON.parse(publicResponse?.data?.data));
        setModuleDetails(courseBlocks?.filter((block) => block.type === "container"));
      }
    } catch (error) {
      console.error("Error fetching course blocks:", error);
    } finally {
      setBlockLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      getCourseBlocks();
    }
  }, [isModalOpen]);

  const groupBlocksBySection = (blocks) => {
    let groupedBlocks = [];
    let currentGroup = [];

    blocks.forEach((block) => {
      if (block.type === "section_break") {
        if (currentGroup.length > 0) {
          groupedBlocks.push(currentGroup); // Only push if the current group is non-empty
        }
        currentGroup = []; // Start a new group after section break
      } else {
        currentGroup.push(block); // Add block to the current group if not a section break
      }
    });

    // Push the last group if it's non-empty
    if (currentGroup.length > 0) {
      groupedBlocks.push(currentGroup);
    }

    return groupedBlocks;
  };

  const groupedBlocks = groupBlocksBySection(containerDetails);
  const maxSteps = groupedBlocks.length;

  const updateUrlWithStep = (section) => {
    const params = new URLSearchParams(location.search);
    params.set("section", section);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const newStep = parseInt(e.target.value, 10);
      if (!isNaN(newStep) && newStep >= 1 && newStep <= maxSteps) {
        setActiveStep(newStep - 1);
      }
      setInputValue(newStep);
      updateUrlWithStep(newStep - 1);
    }
  };

  const handleNext = () => {
    if (activeStep < groupedBlocks.length - 1) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
      setInputValue(nextStep + 1);
      updateUrlWithStep(nextStep);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);
      setInputValue(prevStep + 1);
      updateUrlWithStep(prevStep);
    }
  };

  //   const handleScrollToBlock = (blockId) => {
  //     // Find the section index that contains the block with the specified blockId
  //     const sectionIndex = groupedBlocks.findIndex((section) =>
  //       section.some((item) => item._id === blockId),
  //     );

  //     // If the section is found, set the active step and scroll to the block
  //     if (sectionIndex !== -1) {
  //       setActiveStep(sectionIndex);
  //       setInputValue(sectionIndex + 1);
  //       updateUrlWithStep(sectionIndex);
  //     }
  //   };

  return (
    <Dialog
      fullScreen
      open={isModalOpen}
      sx={{
        "& .MuiDialog-paperFullScreen": { backgroundColor: "#eff6ff" },
        width: "98%",
        height: "98%",
      }}
      onClose={handleModalClose}
    >
      <FormControl sx={{ m: 2, width: 250 }}>
        <InputLabel id="demo-simple-select-label">Module</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedModule}
          label="Module"
          onChange={handleChange}
        >
          {moduleDetails?.map((module) => (
            <MenuItem key={module?.title} value={module?.title}>
              {module?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DialogContent dividers>
        {blockLoading ? (
          <Backdrop open={blockLoading} sx={{ color: "#fff", zIndex: 999 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            {loading ? (
              <CourseDetailsSkeleton />
            ) : containerDetails.length > 0 ? (
              <Box
                sx={{
                  border: maxSteps > 1 ? "1px solid" : "",
                  borderColor: maxSteps > 1 ? "rgba(0, 0, 0, 0.12)" : "",
                }}
              >
                {maxSteps > 1 && (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ width: "100%", position: "sticky", top: 0, zIndex: 1, p: 2, ml: 1 }}
                    >
                      <Typography variant="h6">Section {activeStep + 1}</Typography>

                      <Box display="flex" alignItems="center" sx={{ mr: 2 }}>
                        <input
                          onKeyDown={handleKeyDown}
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          variant="outlined"
                          size="medium"
                          style={{
                            width: 40,
                            padding: 0,
                            textAlign: "center",
                            border: "1px solid",
                            borderRadius: "15%",
                          }}
                        />

                        <Typography variant="h7" sx={{ mx: 1 }}>
                          of {maxSteps}
                        </Typography>

                        <IconButton
                          size="small"
                          variant="outlined"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                          sx={{
                            mx: 1,
                            border: "2px solid #06c",
                            borderRadius: "50%",
                          }}
                        >
                          <ArrowBackIosNewIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="small"
                          variant="outlined"
                          onClick={handleNext}
                          disabled={activeStep === maxSteps - 1}
                          sx={{
                            border: "2px solid #06c",
                            borderRadius: "50%",
                          }}
                        >
                          <ArrowForwardIosIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </Box>

                    <Divider />
                  </>
                )}
                <Grid container spacing={3} columns={100} sx={{ mb: 3, p: maxSteps > 1 ? 3 : 0 }}>
                  {groupedBlocks[activeStep]?.map((item) => (
                    <React.Fragment key={item._id}>
                      {item?.type === "text" && (
                        <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                          <TextViews
                            block={item}
                            theme={item?.theme}
                            art={item?.art}
                            courseDetails={courseOne}
                          />
                        </Grid>
                      )}
                      {item?.type === "button" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <ButtonView
                            key={item}
                            button={item}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}
                      {item?.type === "divider" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <DividerView
                            key={item}
                            divider={item}
                            theme={isLoggedIn ? "light" : "dark"}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}
                      {item?.type === "toggle_list" && item?.items?.length > 0 ? (
                        <Grid item spacing={3} className="fullwidth" md={item?.size?.width}>
                          <ToggleList
                            toggle_list={item?.items}
                            theme={item.theme}
                            block={item}
                            courseDetails={courseOne}
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                      {item?.type === "image" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <ImageView
                            image={item?.image}
                            key={item?.image}
                            theme={item.theme}
                            block={item}
                            courseDetails={courseOne}
                          />
                          {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                        </Grid>
                      )}
                      {item?.type === "audio" && (
                        <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                          <AudioView
                            audio={item?.provider}
                            key={item?.provider}
                            block={item}
                            courseDetails={courseOne}
                          />
                        </Grid>
                      )}
                      {item?.type === "video" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <VideoView video={item} courseDetails={courseOne} block={item} />
                          {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                        </Grid>
                      )}
                      {item?.type === "file" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <FileView
                            file={item?.file}
                            data={item}
                            courseDetails={courseOne}
                            block={item}
                          />
                          {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                        </Grid>
                      )}
                      {item?.type === "link" && (
                        <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                          <LinkView link={item} courseDetails={courseOne} block={item} />
                          {/* {item?.comments?.length !== 0 && (
                      <CommentView comments={item?.comments} />
                    )} */}
                        </Grid>
                      )}
                      {item?.type === "gif" && (
                        <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                          <GifView gif={item?.gif} block={item} courseDetails={courseOne} />
                        </Grid>
                      )}
                      {item?.type === "mcq" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <MCQView
                            mcq={item}
                            mcqs={item.mcqs}
                            blockUserActivities={blockUserActivities}
                            courseDetails={courseOne}
                            block={item}
                            courseOne={courseOne}
                          />
                        </Grid>
                      )}
                      {item?.type === "survey-monkey" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <SurveyMonkeyView block={item} courseDetails={courseOne} />
                        </Grid>
                      )}
                      {item?.type === "scorm" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <ScormView
                            scorm={item}
                            blockUserActivities={blockUserActivities}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}
                      {item?.type === "fill" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <FillView
                            fill={item}
                            blockUserActivities={blockUserActivities}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}
                      {item?.type === "match" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <MatchView
                            match={item}
                            blockUserActivities={blockUserActivities}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}
                      {item?.type === "grid" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <GridView grid={item} courseDetails={courseOne} block={item} />
                        </Grid>
                      )}
                      {item?.type === "response" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <ResponseView
                            response={item}
                            art={item?.art}
                            blockUserActivities={blockUserActivities}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}
                      {item?.type === "list" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <ListView list={item} courseDetails={courseOne} block={item} />
                        </Grid>
                      )}
                      {item?.type === "embed" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <EmbedView embed={item} courseDetails={courseOne} />
                        </Grid>
                      )}
                      {item?.type === "native-embed" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <NativeEmbedView
                            block={item}
                            courseDetails={courseOne}
                            //setblockModOpen={setBlockModalOpen}
                          />
                        </Grid>
                      )}

                      {item?.type === "comic" && (
                        <Grid item className="fullwidth" spacing={3} md={item?.size?.width}>
                          <ComicView
                            text={item.text}
                            image={item.image}
                            block={item}
                            courseDetails={courseOne}
                          />
                        </Grid>
                      )}
                      {item?.type === "container" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <ContainerView
                            container={item}
                            completedModulesData={completedModulesData}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}

                      {item?.type === "carousel_container" && (
                        <Grid item className="fullwidth" lg={item?.size?.width}>
                          <CarosalBlockView
                            carosalContainer={item}
                            courseDetails={courseOne}
                            block={item}
                            courseOne={courseOne}
                          />
                        </Grid>
                      )}

                      {item?.type === "discussion" && (
                        <Grid item className="fullwidth" md={item?.size?.width}>
                          <DiscussionView
                            discussion={item}
                            courseDetails={courseOne}
                            block={item}
                          />
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
              </Box>
            ) : (
              <NothingHere />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SectionModal;
