import React, { useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, CircularProgress, IconButton } from "@mui/material";

import { POST } from "../../../../config/Api";
import { Constants, userRoles } from "../../../../config/Constants";
import { isLearnerView, useQueryParams } from "../../../../utils";
import ButtonMain from "../../../ButtonMain";
import ArtView from "../ArtView/ArtView";
import BlockActions from "../BlockActions/BlockActions";
import "./FillView.scss";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import LearnerProperties from "../../LearnerProperties";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sendDataToFirehose from "../../../../utils/aws-utils";
import clickEvent from "../../../../utils/logging-event-utils";

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));
const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const FillView = ({
  fill,
  courseDetails,
  blockUserActivities,
  setmodalOpen,
  setblockModOpen,
  block,
  disableBlock,
}) => {
  let UserData = useSelector((state) => state.userLogin);
  const moduleDetails = useSelector((state) => state.savedModuleDetail);
  const { cid } = useQueryParams();
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  const [showActions, setShowActions] = useState(false);
  const { courseOne, completedModulesData, userDetails } = useOutletContext() || {};

  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [disable, setDisable] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [checkUserInputs, setcheckUserInputs] = useState(false);
  const [fillsValue, setFillsValue] = useState(
    fill?.fills?.map((item) => {
      return { ...item, value: item?.value || "" };
    }),
  );
  const [blockActivity, setBlockActivity] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleBlockProperties = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toastRef = useRef();
  // const { setAuthStates } = useContext(AuthContext);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};
  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);
  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname.startsWith("/course/createblock") ||
      location.state?.userData?.role === userRoles.REVIEWER
    ) {
      setDisable(true);
    }
  }, [location]);

  useEffect(() => {
    setFillsValue(
      fill?.fills?.map((item) => {
        return { ...item, value: item?.value || "" };
      }),
    );
  }, [fill]);

  useEffect(() => {
    const data = blockUserActivities?.data?.find((item) => item?.block_id === fill?._id);
    setBlockActivity(data);
    const newFills = fillsValue.map((item) => {
      const value = data?.fills.find((el) => el._id === item._id);
      return { ...item, value: value?.value || value?.text };
    });
    setFillsValue(newFills);
    if (data) {
      setcheckUserInputs(true);
    } else {
      setcheckUserInputs(false);
    }
  }, [blockUserActivities]);

  const handleFillSubmit = async () => {
    const result = fillsValue
      ?.filter((item) => item?.is_blank === true)
      .map(({ _id, value }) => ({ _id, value }));

    if (isLoggedIn) {
      try {
        setDisable(true);
        setLoading(true);
        let body = {
          user_id: UserData?.data?.user?.id,
          course_id: cid,
          activity: "fill",
          fills_value: result,
        };
        //Fill in the blank submit fill value
        await POST(`${Constants.saveAndUpdateUserActivities}/${fill?._id}`, body);
        toastRef.current.showToastMessage(
          "Your response has been submitted successfully.",
          "success",
        );
        triggerUserEvent(`${checkUserInputs ? "update" : "save"}`);
        setcheckUserInputs(true);
        setDisable(false);
        setLoading(false);
        //save user last activity
        await POST(
          `${Constants.userActivity}?activity=${
            checkUserInputs ? "blockActivityUpdate" : "blockActivitySave"
          }`,
          {
            user: UserData?.data?.user,
          },
        );
      } catch (error) {
        toastRef.current.showToastMessage("Something went wrong.", "error");
        setLoading(false);
      }
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
    }
  };

  const triggerUserEvent = (action) => {
    if (user?.email && moduleDetails?.data?.containerTitle && isLearnerView(location.pathname)) {
      const eventData = new clickEvent();
      eventData.event = "user_input";
      eventData.user_unique_id = user.email;
      eventData.parent_container_id = moduleDetails.data.id;
      eventData.block_id = block._id;
      eventData.course_id = courseOne._id;
      eventData.cohort_id = completedModulesData?.cohortDetails?._id;
      eventData.course_name = courseOne?.title;
      eventData.course_type = courseOne?.privacy;
      eventData.cohort_name = completedModulesData?.cohortDetails?.title;
      eventData.cohort_type = completedModulesData?.cohortDetails?.type;
      eventData.block_title = moduleDetails?.data?.containerTitle;
      eventData.block_action = action;
      eventData.user_response = fillsValue.map((item) => item.value).join(",");
      eventData.block_type = block?.type;

      // Ensure event tracking is enabled before sending
      if (completedModulesData?.cohortDetails?.eventTracking) {
        eventData.validateAndSendEvent(sendDataToFirehose);
      }
    }
  };

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <Box
          position={"relative"}
          className={` container-block fill-block 
      ${fill?.theme ? fill?.theme : "postBgColor"}
    `}
          style={{
            background: `${fill?.theme_type === "color" ? fill?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              blockId={fill?._id}
              block={fill}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}

          <p className="block-title">
            {fill?.is_required && <span className="required">*</span>}
            {fill?.title}
          </p>
          {fill?.text && (
            <div className="block-text" dangerouslySetInnerHTML={{ __html: fill?.text }}></div>
          )}
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {fillsValue &&
              fillsValue?.map((item, index) => (
                <div key={item?._id} className="fill-contents">
                  <React.Fragment>
                    {item?.is_blank ? (
                      <input
                        placeholder=""
                        type="text"
                        autocomplete="off"
                        disabled={disableBlock}
                        className="blank-fill entity-title"
                        value={item?.value || ""}
                        onChange={(e) => {
                          const newFills = [...fillsValue];
                          newFills[index].value = e.target.value;
                          setFillsValue(newFills);
                        }}
                      />
                    ) : (
                      <span className="one-fill">{item?.text}</span>
                    )}
                  </React.Fragment>
                </div>
              ))}{" "}
          </div>
          <ButtonMain
            onClick={handleFillSubmit}
            disabled={disable || disableBlock || !isLoggedIn}
            style={{ padding: `${loading ? "5px 25px" : ""}` }}
          >
            {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
          </ButtonMain>
          {userDetails?.role === userRoles.REVIEWER && (
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleBlockProperties}
              sx={{ float: "right" }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
          <ArtView art={(block || fill)?.art} />
          <ToastNotify ref={toastRef} duration={3000} />
          {userDetails?.role === userRoles.REVIEWER && (
            <LearnerProperties
              anchorEl={anchorEl}
              open={open}
              handleClose={() => setAnchorEl(null)}
              obtainedMark={blockActivity?.credit}
              maxMark={block?.score || 0}
              blockType={block?.type}
              attempts={blockActivity?.NoOfAttempts}
              keywords={block?.keywords}
              scoreType={block?.typeOfBlock}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default FillView;
