import React, { useState, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, Collapse, Skeleton } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParams } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setEntrySourceAction } from "../../../redux/action";
import { ReactComponent as QueryIcon } from "../../../assets/QuerryIcon.svg";
import { ReactComponent as BlueQueryIcon } from "../../../assets/blueQuery.svg";

const IncorrectBlocks = ({ data, userDetails, loading }) => {
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [openContainers, setOpenContainers] = useState({});
  const { slug } = useParams();
  const { cid } = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};

  function stripHTML(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  useEffect(() => {
    // Initialize open state for each container to false
    const initialOpenState = data.reduce((acc, item) => {
      acc[item.container] = false;
      return acc;
    }, {});
    setOpenContainers(initialOpenState);
  }, [data]);

  const handleToggle = (containerId) => {
    setOpenContainers((prev) => ({
      ...prev,
      [containerId]: !prev[containerId],
    }));
  };

  const handleModuleOpen = (item) => {
    if (isLoggedIn) dispatch(setEntrySourceAction("menu_clicked"));
    setSelectedBlockId(item._id);
    navigate(`/course/${slug}/${item?.slug}?cid=${cid}&mid=${item?.container}`, {
      state: {
        userData: userDetails,
        incorrectBlock: item?._id,
      },
    });
  };

  const groupedData = {};
  data.forEach((item) => {
    const containerId = item.container;
    if (!groupedData[containerId]) {
      groupedData[containerId] = {
        containerDetails: item.containerDetails,
        blocks: [],
      };
    }
    groupedData[containerId].blocks.push(item);
  });

  return (
    <Box>
      {loading ? (
        <Skeleton variant="rounded" width={250} height={60} sx={{ bgcolor: "#4f89c3" }} />
      ) : Object.keys(groupedData).length > 0 ? (
        Object.keys(groupedData).map((containerId, index) => (
          <>
            <ListItemButton
              key={index}
              onClick={() => handleToggle(containerId)}
              sx={{
                // bgcolor: openContainers[containerId] ? "#FFFFFF" : "", openContainers[containerId] ? "#0051A2" :
                borderRadius: "6px",
                p: 0,
                ml: 1,
                mt: 1,
                opacity: 0.95,
                color: "white",
                borderLeft: "6px solid transparent",
                borderBottom: "1px solid #fff",
                "& >div>.MuiListItemText-secondary": {
                  color: "#ffffffa1",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },

                "&:hover": {
                  bgcolor: "#4f89c3",
                  color: "white",
                  cursor: "pointer",
                  "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
                },
                "&.Mui-selected": {
                  color: "black",
                  "& >div>.MuiListItemText-secondary": {
                    color: "#333333",
                  },
                  bgcolor: "#ffffff",
                  borderTop: "1px solid #365082 ",
                  borderLeft: "6px solid #01CE3E",
                  borderBottom: "1px solid #fff",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "auto", ml: 1, mr: 1, color: "white" }}>
                {openContainers[containerId] ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
              <ListItemText primary={groupedData[containerId].containerDetails.title} />
              <Button
                size="small"
                variant={"contained"}
                sx={{
                  bgcolor: "#B3003D",
                  border: "2px solid #fff",
                  borderRadius: "50%",
                  minWidth: "17px",
                  minHeight: "17px",
                  lineHeight: "0.50",
                  padding: 0,
                  mr: 1,
                }}
              >
                {groupedData[containerId].blocks.length}
              </Button>
            </ListItemButton>

            <Collapse in={openContainers[containerId]} timeout="auto" unmountOnExit>
              {groupedData[containerId].blocks.map((block, index) => (
                <ListItemButton
                  key={block._id}
                  onClick={() => handleModuleOpen(block)}
                  sx={{
                    ml: 4,
                    padding: 0,
                    mt: 1,
                    bgcolor: selectedBlockId === block?._id ? "#FFFFFF" : "",
                    borderRadius: "6px",
                    opacity: 0.95,
                    color: selectedBlockId === block?._id ? "#0051A2" : "white",
                    borderLeft: "3px solid transparent",
                    borderBottom: "1px solid #fff",
                    "& >div>.MuiListItemText-secondary": {
                      color: "#ffffffa1",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },

                    "&:hover": {
                      bgcolor: "#4f89c3",
                      color: "white",
                      cursor: "pointer",
                      "& >div>.MuiListItemText-secondary": { color: "#ffffffa1" },
                    },
                    "&.Mui-selected": {
                      color: "black",
                      "& >div>.MuiListItemText-secondary": {
                        color: "#333333",
                      },
                      bgcolor: "#ffffff",
                      borderBottom: "1px solid #fff",
                      borderLeft: "6px solid #01CE3E",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "auto", mr: 1, ml: 1 }}>
                    {selectedBlockId === block?._id ? (
                      <BlueQueryIcon fontSize={"small"} />
                    ) : (
                      <QueryIcon fontSize={"small"} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={stripHTML(block.title)} />
                </ListItemButton>
              ))}
            </Collapse>
          </>
        ))
      ) : (
        <ListItemButton
          sx={{
            // bgcolor: openContainers[containerId] ? "#FFFFFF" : "", openContainers[containerId] ? "#0051A2" :
            borderRadius: "6px",
            opacity: 0.95,
            borderBottom: "1px solid #8c8585",
            "& >div>.MuiListItemText-secondary": {
              color: "#ffffffa1",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        >
          <ListItemText
            primary="No incorrect answers found"
            sx={{
              ml: 2,
              color: "white",
            }}
          />
        </ListItemButton>
      )}
    </Box>
  );
};

export default IncorrectBlocks;
