import React, { useEffect, useState, useRef } from "react";
import BlockViews from "./BlockViews";
import { GET } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Container, Divider } from "@mui/material";
import KeywordsView from "../../Keywords/KeywordsView";
import { CourseDetailsSkeleton } from "../../Loader/Loader";
import { useSelector } from "react-redux";
import "./download.scss";

const DownloadCourseView = () => {
  const [blockDetails, setBlockDetails] = useState([]);
  const [courseOne, setCourseOne] = useState({});
  const [loading, setLoading] = useState(true);
  const { courseID } = useParams();
  const navigate = useNavigate();
  const contentRef = useRef();

  let isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else {
      if (courseID) {
        setLoading(true);
        Promise.all([fetchCourseDetails(), getCourseBlocks()])
          .catch((error) => {
            console.error("Error loading course data:", error);
            navigate("/404");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [courseID, isLoggedIn]);

  useEffect(() => {
    if (!loading) {
      window.print();
    }
  }, [loading]);

  const fetchCourseDetails = async () => {
    try {
      const response = await GET(`${Constants.getPublicCourseDetail}/${courseID}`);
      setCourseOne(response?.data?.data || {});
    } catch (err) {
      console.error("Failed to fetch course details:", err);
      navigate("/404");
    }
  };

  const getCourseBlocks = async () => {
    try {
      const response = await GET(`${Constants.course}/nestedbblocks/${courseID}`);
      setBlockDetails(response?.data?.blocks || []);
    } catch (err) {
      console.error("Failed to fetch course blocks:", err);
    }
  };

  return (
    <div id="courseContent" ref={contentRef}>
      {/* Watermark for print preview */}
      <div className="watermark">FRAMERSPACE</div>
      <Container maxWidth="xl" sx={{ width: "97%", py: 3 }}>
        <Typography variant="h2" className="fsTitle">
          Framerspace
        </Typography>
        <Divider className="fsDivider" />
        <div className="title-container">
          <h2>{courseOne?.title}</h2>
          <Typography
            dangerouslySetInnerHTML={{ __html: courseOne?.tagline }}
            variant="body1"
            sx={{
              margin: "0px",
              fontSize: "1.14286rem",
              lineHeight: 1.5,
            }}
          />
          <KeywordsView keywords={courseOne} />
        </div>
        {loading ? (
          <CourseDetailsSkeleton />
        ) : (
          blockDetails?.length > 0 && (
            <BlockViews blockDetails={blockDetails} courseOne={courseOne} />
          )
        )}
      </Container>
    </div>
  );
};

export default DownloadCourseView;
