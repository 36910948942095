import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import { Box, Typography, IconButton, Tabs, Tab, Tooltip } from "@mui/material";
import NavigateItem from "./NavigateItem";
import "./Navigate.scss";
import { ReactComponent as CourseNavigateIcon } from "../../../assets/CourseNavigate.svg";
import { ReactComponent as IncorrectAnswerIcon } from "../../../assets/WrongAnswer.svg";
//import { ReactComponent as ModalPopUpIcon } from "../../../assets/ModalPopUp.svg";
import { ReactComponent as GoBackIcon } from "../../../assets/goBack.svg";
import SectionModal from "../CourseLayout/SectionModal";
import { POST } from "../../../config/Api";
import { Constants } from "../../../config/Constants";
import { useQueryParams } from "../../../utils";
import IncorrectBlocks from "./IncorrectBlocks";

export default function Navigate({
  modDetails,
  setCourseNav,
  open,
  setOpen,
  courseId,
  userDetails,
  courseOne,
  completedModulesData,
}) {
  const isLoggedIn = useSelector((state) => state.userLogin?.data?.isLoggedIn);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const { cid } = useQueryParams();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleIncorrectAnswers = async () => {
    setLoading(true);
    try {
      const res = await POST(`${Constants.checkIncorrectBlocks}/${cid}`);
      if (res?.data?.success) {
        setLoading(false);
      } else {
        if (res?.data?.incorrectBlocks?.length > 0) {
          setIncorrectAnswers(res.data.incorrectBlocks);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching incorrect blocks:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleIncorrectAnswers();
  }, []);

  return (
    <>
      {isLoggedIn && (
        <Box className="navigate">
          {open && (
            <div style={{ position: "sticky", top: 120 }}>
              <List
                sx={{
                  width: { xs: 320, sm: "290px" },
                  marginRight: "20px",
                  borderRadius: "3px",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" sx={{ bgcolor: "#4f89c3", color: "white", p: 1 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography component={"h4"} variant="body1" fontWeight={600}>
                        {selectedTab === 0 && "Course Navigation"}
                        {selectedTab === 1 && "Incorrect Answers"}
                      </Typography>
                      <IconButton onClick={() => setCourseNav(false)}>
                        <GoBackIcon fontSize="small" fill="#fff" height={"21px"} width={"23px"} />
                      </IconButton>
                    </Box>
                  </ListSubheader>
                }
              >
                <Box display="flex" sx={{ width: "100%", height: "70vh" }}>
                  <Tabs
                    orientation="vertical"
                    value={selectedTab}
                    onChange={handleTabChange}
                    textColor="inherit"
                    indicatorColor="primary"
                    aria-label="vertical navigation tabs"
                    sx={{
                      width: "18%",
                      bgcolor: "#365082",
                      minWidth: "20px",
                      minHeight: "40px",
                      //marginBottom: "2rem",
                      padding: 0,
                      color: "white",

                      opacity: 1,
                      "& .Mui-selected": {
                        opacity: "2",
                        minWidth: "20px",
                        minHeight: "40px",
                        padding: "2px 2px",
                        color: "#0051A2",
                      },
                    }}
                  >
                    <Tooltip title="Course Navigation">
                      <Tab
                        icon={<CourseNavigateIcon fontSize="large" />}
                        aria-label="Course Modules"
                        sx={{ minWidth: "20px", minHeight: "40px", padding: 0, mt: 1 }}
                      />
                    </Tooltip>
                    <Tooltip title="Incorrect Answers">
                      <Tab
                        onClick={(e) => {
                          handleIncorrectAnswers();
                        }}
                        icon={<IncorrectAnswerIcon fontSize="large" />}
                        aria-label="Wrong Answers"
                        sx={{ minWidth: "20px", minHeight: "40px", padding: 0, mt: 1 }}
                      />
                    </Tooltip>
                    {/* <Tooltip title="Fullscreen Mode">
                      <Tab
                        icon={<ModalPopUpIcon fontSize="large" />}
                        aria-label="Explore All"
                        sx={{ minWidth: "50px" }}
                        onClick={(e) => {
                          handleModalOpen();
                        }}
                      />
                    </Tooltip> */}
                  </Tabs>
                  <Box sx={{ flexGrow: 1, width: "82%", overflow: "auto", bgcolor: "#365082" }}>
                    {selectedTab === 0 && (
                      <Box>
                        {/* Course Modules Content */}
                        {modDetails?.map((item, index) => (
                          <Box key={index} m={1}>
                            <NavigateItem
                              data={item}
                              setOpen={setOpen}
                              courseId={courseId}
                              userDetails={userDetails}
                              completedModulesData={completedModulesData}
                            />
                          </Box>
                        ))}
                      </Box>
                    )}

                    {selectedTab === 1 && (
                      <Box>
                        {/* {incorrectAnswers?.map((item, index) => ( */}
                        <Box m={1}>
                          <IncorrectBlocks
                            data={incorrectAnswers}
                            setOpen={setOpen}
                            loading={loading}
                            courseId={courseId}
                            userDetails={userDetails}
                          />
                        </Box>
                        {/* ))} */}
                      </Box>
                    )}

                    {selectedTab === 2 && (
                      <Box>
                        {isModalOpen && (
                          <Box m={1}>
                            <SectionModal
                              isModalOpen={isModalOpen}
                              handleModalClose={handleModalClose}
                              courseOne={courseOne}
                              completedModulesData={completedModulesData}
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </List>
            </div>
          )}
        </Box>
      )}
    </>
  );
}
